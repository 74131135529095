<template>
    <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
            <a-row :gutter="8">
                <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                    <a-form-model-item label="名称">
                        <a-input v-model="searchData.name" placeholder="名称"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :xxl="6" :xl="5" :md="8" sm="2">
                    <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                        <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                        <a-button type="default" @click="reset()" icon="sync">重置</a-button>
                    </a-form-model-item>
                </a-col>
            </a-row>
        </a-form-model>
        <div>
            <a-button v-if="onAdd" type="primary" @click="toHandler('add')">新增</a-button>
            <a-button v-if="onEdit" type="primary" @click="toHandler('edit')">修改</a-button>
            <a-button v-if="onDelete" type="primary" @click="toHandler('del')">删除</a-button>
        </div>
      <a-row :gutter="8">
        <a-col :span="6">
          <div class="tree-outer">
            <a-tree :treeData="dataList"
              v-if="dataList.length>0"
              :expanded-keys="expandedKeys"
              @expand="onExpand"
              @select="handleSelect">
            </a-tree>
          </div>
        </a-col>
        <a-col :span="18">
          <!-- 数据表格 -->
          <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
             :columns="columns" :data-source="tableData" bordered :pagination="page"
             @change="changeTable" :customRow="changeTableRow">
             <span slot="flagEnable" slot-scope="text,record">
                <a-switch v-model="record.flagEnable" @click="onChangeStatus(record)" />
            </span>
          </a-table>
        </a-col>
      </a-row>

        <!-- 编辑表单 -->
        <FirmStation-edit-modal ref="FirmStationEditModal" @reload="getData"></FirmStation-edit-modal>
    </div>
</template>

<script>
import { columns } from './components/colums.js'
import FirmStationEditModal from './components/FirmStationEditModal.vue'
import { delFirmStation, listFirmStation, selectByIdFirmStation } from './api/FirmStationApi'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
    name: 'firmStation',
    components: {
        FirmStationEditModal
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
            selectedRowKeys: [],
            selectedRows: [],
            expandedKeys: [0],
            dataList: [],
            StationId: 0,
            onAdd: checkPermission('firm:station:add'),
            onEdit: checkPermission('firm:station:edit'),
            onDelete: checkPermission('firm:station:delete'),
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            listFirmStation ({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                sourceType: 1,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = records
                }
            }).finally(() => this.tableLoading = false)
        },

        // 获取左侧树
        getTreeList() {
          this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then(res => {
            this.dataList = res.body
          })
        },
        onExpand(expandedKeys) {
          this.expandedKeys = expandedKeys;
          this.autoExpandParent = false;
        },
        // 选中筛选
        handleSelect(value) {
          let id = Number(value.toString())
          this.StationId = id
          this.getData()
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            this.page = Object.assign({}, this.page)
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            this.page = Object.assign({}, this.page)
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        console.log(e)
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        toHandler(name) {
            const _this = this
            if (name === 'add') {
                _this.$refs.FirmStationEditModal.setRowData({}, 'add')
                return
            }
            if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                    message: '请选择一条记录'
                })
                return
            }
            switch (name) {
                case 'edit':
                    _this.$refs.FirmStationEditModal.setRowData(_this.selectedRows[0], 'edit')
                    break
                case 'del':
                    _this.$confirm({
                        title: '删除警告',
                        content: '确认要删除此条记录吗?',
                        okText: '确认',
                        cancelText: '取消',
                        async onOk() {
                            const res = await delFirmStation(_this.selectedRows[0].id)
                            if (res.code === 200) {
                                _this.$notification.success({ message: res.message })
                                this.getData()
                            } else {
                                _this.$notification.error({ message: res.message })
                            }
                        },
                        onCancel() {}
                    })
                    break
            }
        },
        onChangeStatus (record) {
            let status = record.flagEnable
            this.axios.get(`/api/firm/firm/firmStation/status/isEnable?stationId=${record.id}&flagEnable=${status}`).then(res => {
                if(res.code == 200) {
                    this.$message.success(res.message)
                    this.getData()
                }else {
                    this.getData()
                }
            })
        }
    },
    created() {
        this.getData()
        this.getTreeList()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
    .tree-outer {
      height: 500px;
      overflow-y: auto;
    }
</style>
