export const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '所属部门',
    dataIndex: 'deptName',
    key: 'deptName',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '是否启用',
    dataIndex: 'flagEnable',
    key: 'flagEnable',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'flagEnable'}
  },
  {
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
]
